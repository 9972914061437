<template>
    <div class="main-bg">
        <div class="main-bg-wrapper">
            <div class="logo">
                <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
                <img :src="require('@/assets/images/logo-name.png')">
            </div>
            <div class="form-inner-2">

                 <div class="tick-icon">
                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </div>
                <div class="form-text">
                    <p >A reset link has been sent to your email id<br> 
                    {{email}}</p>
                </div>


             
                <div class="back-to-login">
                    <router-link to="/" replace>BACK TO LOGIN</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ResetPasswordBlank',
        data () {
            return {
                email:this.$store.state.email
            }
        },
        props: {
        },
        methods: {

  
        },
        mounted(){

        },
        created(){

        },
       
    }

</script>


<style>
</style>